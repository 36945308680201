import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import "./index.css";
import awardIcon from "../../images/award.png";

const Awards = () => {
  const user = useRef(null);
  const [allAwards, setAllAwards] = useState([]);
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const [page, setpage] = useState(1);
  const itemsperpage = 6;
  const lastindex = page * itemsperpage;
  const firstindex = lastindex - itemsperpage;
  const [records, setrecords] = useState(allAwards.slice(firstindex, lastindex));

  useEffect(() => {
    // console.log("page,pins");
    const lastindex = page * itemsperpage;
    const firstindex = lastindex - itemsperpage;
    setrecords(allAwards.slice(firstindex, lastindex));
  }, [page, allAwards]);

  useEffect(() => {
    const getdata = async (url) => {
      const response = await fetch(process.env.REACT_APP_BASEURL + "/collectors/awards", {
        headers: {
          Authorization: `Bearer ${user.current.sessionToken}`,
        },
      });
      const allpins = await response.json();
      setAllAwards(allpins);
    };

    user.current = JSON.parse(localStorage.getItem("user"));
    if (!user.current) {
      return navigate("");
    }
    getdata();
  }, [navigate, query]);

  const handlepagechange = (newpage) => setpage(newpage);

  return (
    <div className="pins-template">
      <br />
      <br />
      <br />
      <h2>Awards ({allAwards.length})</h2>

      <div className="container-fluid">
        <div className="row">
          {records.map((pin) => {
            return (
              <div className="col-12 col-md-4 col-lg-4 mb-4" id={pin.id}>
                <div className="card mx-auto text-center" id={"inside-" + pin.id} onClick={() => navigate(`/awards/${pin.id}`)}>
                  <img
                    style={{ cursor: "pointer", padding: 5 }}
                    src={pin.awardimage}
                    className="pin-image card-img-top mx-auto d-block"
                    alt={pin.name}
                  />

                  <div
                    className="card-body"
                    style={{
                      borderTop: "1px solid silver",
                    }}
                    id={"insides-" + pin.id}
                  >
                    <h4 className="card-title">{pin.name}</h4>
                    <h5 className="card-title">{pin.orgname}</h5>
                    <p className="card-text">Token #{pin.tokennumber}</p>
                    <img
                      src={awardIcon}
                      title="Award PiNs"
                      alt=""
                      style={{ textAlign: "center", width: 100, height: 100 }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PaginationBasic
        active={page}
        totalpages={Math.ceil(allAwards.length / itemsperpage)}
        handlepagechange={handlepagechange}
      ></PaginationBasic>
    </div>
  );
};

export default Awards;

const PaginationBasic = ({ active, totalpages, handlepagechange }) => {
  let items = [];
  for (let number = 1; number <= totalpages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => handlepagechange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
};
