import { useNavigate } from "react-router";
import cliftylogo from "../../images/clifty-transparents.png";
import AlertPopup from "../Alert/alert";

import "./header.css";
import { useContext } from "react";
import { Alertcontext, loginstatuscontext } from "../../AppContext";
import { Link, NavLink } from "react-router-dom";

function Header({ setmsg }) {
  const navigate = useNavigate();
  const alertContext = useContext(Alertcontext);
  const logincontext = useContext(loginstatuscontext);
  const setShow = () => {
    alertContext.setAlert({
      ...alertContext.Alert,
      show: true,
    });
  };
  const handlesignout = () => {
    alertContext.setAlert({
      show: true,
      variant: "primary",
      message: "You’ve been successfully logged out. See you next time!",
    });
    // window.location.replace("/")
    setTimeout(() => {
      alertContext.setAlert({
        show: false,
        variant: "primary",
        message: "You’ve been successfully logged out. See you next time!",
      });
      navigate("/", { replace: true });
      logincontext.setisLogin(false);
    }, 1000);

    localStorage.removeItem("user");
  };

  const handleDrawer = () => {
    const el = document.getElementById("navbarNav");
    // console.log(el.style.display, " is the display");
    el.style.display = el.style.display === "none" ? "block" : "none";
    // console.log(el.style.display, " is the display changed");
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-static-top navbar-light "
        style={{ backgroundColor: "#F4F9FF" }}
      >
        <div className="container-fluid" style={{ height: "50px" }}>
          <Link
            to={logincontext.islogin ? "/gallery" : "/"}
            className="navbar-brand"
          >
            <img
              src={cliftylogo}
              className="img"
              alt=""
              style={{ marginRight: "6px" }}
            />
            {logincontext && logincontext.islogin ? "" : ""}
            Collector{" "}
            {process.env.REACT_APP_INSTANCE === "Production"
              ? ""
              : " (" + process.env.REACT_APP_INSTANCE + ")"}
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleDrawer}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            style={{
              display: "none",
              backgroundColor: "#F4F9FF",
              textAlign: "center",
            }}
            id="navbarNav"
          >
            {logincontext && logincontext.islogin && (
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className={({ isActive }) => isActive ? "nav-link active-navlink" : "nav-link"} to="/gallery" onClick={handleDrawer}>Souvenirs</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className={({ isActive }) => isActive ? "nav-link active-navlink" : "nav-link"} to="/awards" onClick={handleDrawer}>Awards</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className={({ isActive }) => isActive ? "nav-link active-navlink" : "nav-link"} to="/profile" onClick={handleDrawer}>Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={(handleDrawer, handlesignout)}>
                    Signout
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      <br />
      <div className="container">
        {alertContext.Alert.show && (
          <AlertPopup Message={alertContext.Alert.message} variant={alertContext.Alert.variant} setShow={() => setShow()} />
        )}
      </div>
    </>
  );
}

export default Header;
