import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import "./index.css";
import presentationIcon from "../../images/presentation.png";

const AllPresentations = () => {
  const user = useRef(null);
  const [allPresentations, setAllPresentations] = useState([]);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const itemsperpage = 6;
  const lastindex = page * itemsperpage;
  const firstindex = lastindex - itemsperpage;
  const [records, setrecords] = useState(allPresentations.slice(firstindex, lastindex));

  // Get last parameters from url param as eventid
  const eventId = window.location.pathname.split("/").pop();


  const getdata = useCallback(async () => {
    const response = await fetch(process.env.REACT_APP_BASEURL + "/collectors/events/" + eventId + "/presentations", {
      headers: {
        Authorization: `Bearer ${user.current.sessionToken}`,
      },
    });
    const all = await response.json();
    setAllPresentations(all);
  }, [eventId]);

  useEffect(() => {
    // console.log("page,pins");
    const lastindex = page * itemsperpage;
    const firstindex = lastindex - itemsperpage;
    setrecords(allPresentations.slice(firstindex, lastindex));
  }, [page, allPresentations]);

  useEffect(() => {
    user.current = JSON.parse(localStorage.getItem("user"));
    if (!user.current) {
      return navigate("");
    }
    getdata();
  }, [getdata, navigate, query]);

  const handlepagechange = (newpage) => setpage(newpage);

  return (
    <div className="pins-template">
      <br />
      <br />
      <Link to="/gallery" style={{ textDecoration: "none" }}>
        {" "}
        &larr; Back to Souvenirs
      </Link>
      <br />
      <h2>Presentations ({allPresentations.length})</h2>

      <div className="container-fluid">
        <div className="row">
          {records.map((pin) => {
            return (
              <><div className="col-12 col-md-4 col-lg-4 mb-4">
                <div className="card mx-auto text-center">
                  <img
                    style={{ padding: 5 }}
                    src={presentationIcon}
                    className="pin-image card-img-top mx-auto d-block"
                    alt={pin.name} />

                  <div className="card-body" style={{ borderTop: "1px solid silver", }} >
                    <h4 className="card-title">{pin.name}</h4>
                    <h5 className="card-title">By{" "}
                      <a href={`mailto:${pin.authoremail}`}>
                        {pin.authorname}
                      </a>
                    </h5>
                    <p className="card-text">{pin.description}</p>

                    <a href={pin.presentation} target="_blank" rel="noreferrer" download={pin.filename} className="btn btn-primary">
                      View Presentation
                    </a>
                  </div>
                </div>
              </div>
              </>
            );
          })}
        </div>
      </div>
      <PaginationBasic
        active={page}
        totalpages={Math.ceil(allPresentations.length / itemsperpage)}
        handlepagechange={handlepagechange}
      ></PaginationBasic>
    </div>
  );
};

export default AllPresentations;

const PaginationBasic = ({ active, totalpages, handlepagechange }) => {
  let items = [];
  for (let number = 1; number <= totalpages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => handlepagechange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
};
