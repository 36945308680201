import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { AlertContext, ForgotPasswordprovider, LoginContext } from "./AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Redirect to https if in production or staging
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
  if (window.location.protocol !== "https:") {
    window.location = window.location.href.replace("http://", "https://");
  }
}

root.render(
  <BrowserRouter>
    <AlertContext>
      <LoginContext>
        <ForgotPasswordprovider>
          <App />
        </ForgotPasswordprovider>
      </LoginContext>
    </AlertContext>
  </BrowserRouter>
);
