import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alertcontext, ForgotPasswordContext } from "../../AppContext";
import AlertPopup from "../Alert/alert";
import "./index.css";
import ValidPassword from "../Password/ValidPassword";

const NewPassword = () => {
  const [searchParams] = useSearchParams();
  const [email, setemail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const alertContext = useContext(Alertcontext);
  const forgotPasswordcontext = useContext(ForgotPasswordContext);
  const code = searchParams.get("code");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASEURL}/collectors/search?code=${code}`)
      .then((res) => res.json())
      .then((user) => {
        if (user["error"]) throw new Error(user.error);

        setemail(user.email);
      })
      .catch((error) => {
        forgotPasswordcontext.setMessage(error.message);
        navigate("/user/forgotpassword");
      });
  }, [code, forgotPasswordcontext, navigate]);
  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      setError("Enter new password.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const res = await fetch(
        process.env.REACT_APP_BASEURL + "/collectors/changepassword",
        {
          method: "post",
          body: JSON.stringify({
            password,
            code,
          }),
          headers: { "content-type": "application/json" },
        }
      );

      const data = await res.json();
      console.log(data);
      if (data?.success) {
        alertContext.setAlert({
          show: true,
          variant: "primary",
          message:
            "Password changed successfully! Please sign in with the new password",
        });

        navigate("/signin");
      } else {
        console.log(data);
        throw new Error(data.error || "Error resetting password");
      }
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError("");
        navigate("/signin");
      }, 2000);
    }
  };

  return (
    <div className="col-md-2 col-lg-4 mx-auto">
      {error && (
        <AlertPopup
          Message={error}
          variant="danger"
          setShow={() => setError(null)}
        />
      )}
      <h3 className="login-heading mb-4">Forgot Password</h3>
      {code && (
        <>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              value={email}
              readOnly={true}
              disabled
            />
            <label htmlFor="email">Email</label>
          </div>
          {/* <div className="form-floating mb-3"> */}

          <ValidPassword
            label="New Password"
            name="New Password"
            id="key"
            datatestid="key1"
            value={password}
            setvalue={setPassword}
            validcheck={{
              hasUppercase: {
                valid: false,
                message: "At least one Upper Case",
              },
              hasLowercase: {
                valid: false,
                message: "At least one Lower Case",
              },

              hasNumber: { valid: false, message: "At least one number" },
              hasMinlength: { valid: false, message: "Minimum 8 characters" },
              hasSpecialChar: {
                valid: false,
                message: "At least one special character",
              },
            }}
          />
          {/* <input
              type="password"
              className="form-control"
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          {/* <label htmlFor="email">New Password</label> */}
          {/* </div> */}
          {/* <div className="form-floating mb-3"> */}

          <ValidPassword
            label="Password Confirmation"
            name="Password Confirmation"
            id="keys"
            datatestid="key2"
            value={confirmPassword}
            setvalue={setConfirmPassword}
            comparewith={password}
            validcheck={{
              passwordMatch: {
                valid: false,
                message: {
                  failure: "Passwords don't match!",
                  success: "Password matches",
                },
              },
            }}
          />
          {/* <input
              type="password"
              className="form-control"
              onChange={(e) => setConfirmPassword(e.target.value)}
            /> */}
          {/* <label htmlFor="email">Confirm Password</label> */}
          {/* </div> */}
          <div className="d-grid">
            <button
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
              style={{ fontSize: "16px" }}
              onClick={handleResetPassword}
              type="button"
            >
              Reset Password
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default NewPassword;
