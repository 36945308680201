import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { Form } from "react-bootstrap";
import "./index.css";
import presentationIcon from "../../images/presentation.png";

const AllSouvenirs = () => {
  const user = useRef(null);
  const [pins, setpins] = useState([]);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASEURL + "/collectors/souvenirs";
  const [page, setpage] = useState(1);
  const itemsperpage = 6;
  const lastindex = page * itemsperpage;
  const firstindex = lastindex - itemsperpage;
  const [records, setrecords] = useState(pins.slice(firstindex, lastindex));
  const [sortby, setsortby] = useState("");
  const getdata = async (url) => {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${user.current.sessionToken}`,
      },
    });
    const allpins = await response.json();
    setpins(allpins);
    // setsortby("tokennumber");
    return allpins;
  };

  useEffect(() => {
    // console.log("page,pins");
    const lastindex = page * itemsperpage;
    const firstindex = lastindex - itemsperpage;
    setrecords(pins.slice(firstindex, lastindex));
  }, [page, pins]);

  useEffect(() => {
    // console.log("navigate,query,url");
    user.current = JSON.parse(localStorage.getItem("user"));
    if (!user.current) {
      return navigate("");
    }
    getdata(url);
  }, [navigate, query, url]);

  useEffect(() => {
    // let newdata = [];
    // console.log("pins,sortby");
    if (sortby === "tokennumber") pins.sort(sortbytoken);
    else if (sortby === "A-Z") pins.sort((a, b) => sortbyalphabet(a, b, true));
    else if (sortby === "Z-A") pins.sort((a, b) => sortbyalphabet(a, b, false));
    // console.log(pins);
    setpins([...pins]);
  }, [sortby]);
  const handlepagechange = (newpage) => setpage(newpage);

  const handlesort = (e) => {
    const { value } = e.target;
    setsortby(value);
  };
  const sortbytoken = (token1, token2) => {
    if (token1.tokennumber < token2.tokennumber) return -1;
    else if (token1.tokennumber > token2.tokennumber) return 1;
    return 0;
  };

  const sortbyalphabet = (token1, token2, order) => {
    const name1 = token1.name.toLowerCase();
    const name2 = token2.name.toLowerCase();

    if (name1 < name2) return order ? -1 : 1;
    else if (name1 > name2) return order ? 1 : -1;
    return 0;
  };
  return (
    <div className="pins-template">
      <br />
      <br />
      <br />
      <h2>Souvenirs ({pins.length})</h2>
      <Form.Select
        className="select-filter"
        aria-label="Select sortying criteria"
        onChange={handlesort}
        value={sortby}
      >
        <option disabled selected value="">
          Sort By
        </option>
        <option value="tokennumber">Token Number</option>
        <option value="A-Z">A-Z</option>
        <option value="Z-A">Z-A</option>
      </Form.Select>
      <div className="container-fluid">
        <div className="row">
          {records.map((pin) => {
            return (
              <><div className="col-12 col-md-4 col-lg-4 mb-4">
                <div className="card mx-auto text-center">
                  <a href={`/pins/${pin.uid}`}>
                    <img
                      style={{ padding: 5 }}
                      src={pin.artifact}
                      className="pin-image card-img-top mx-auto d-block"
                      alt={pin.name} />
                  </a>

                  <div
                    className="card-body"
                    style={{
                      borderTop: "1px solid silver",
                    }}
                  >
                    <h4 className="card-title">
                      {pin.presentations > 0 ? (
                        <img
                          src={presentationIcon}
                          title="Presentations Available!"
                          className="wedimg"
                          alt=""
                          onClick={() => navigate(`/presentations/${pin.events_id}`)}
                          style={{ marginLeft: "90%" }} />
                      ) : <>
                        <br />
                      </>}
                      {pin.name}
                    </h4>
                    <h5 className="card-title">{pin.company}</h5>
                    <p className="card-text">Token #{pin.tokennumber}</p>


                  </div>
                </div>
              </div>
              </>
            );
          })}
        </div>
      </div>
      <PaginationBasic
        active={page}
        totalpages={Math.ceil(pins.length / itemsperpage)}
        handlepagechange={handlepagechange}
      ></PaginationBasic>
    </div>
  );
};

export default AllSouvenirs;

const PaginationBasic = ({ active, totalpages, handlepagechange }) => {
  let items = [];
  for (let number = 1; number <= totalpages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => handlepagechange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
};
